import React, {useEffect, useState} from 'react';
import DataService from "../../../services/DataService";
import {Avatar, Grid, Typography, makeStyles, Theme, Button} from "@material-ui/core";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";
import DataTable from "react-data-table-component";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        spacing: theme.spacing(1),
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
}));

const UserList = () => {
    const [users, setUsers] = useState<User[]>();
    const [selectedUser, setSelectedUser] = useState<User>();
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const columns = [
        {
            name: '',
            selector: 'picture',
            grow:1,
            cell: (row: User) => <Avatar alt={'Profile Picture'} src={row.picture}/>
        },
        {
            name: 'Full Name',
            selector: 'firstname',
            grow:4,
            cell: (row: User) => <Typography>{row.firstname} {row.lastname}</Typography>
        },
        {
            name: 'Email',
            selector: 'email',
            grow:4
        },
        {
            name: 'Role',
            selector:'organizationUser',
            grow:4,
            right:true,
            cell: (row:User) => <Typography>{row.organizationUser.find(orga => orga.organization_id === DataService.organizationId)?.role}(active)</Typography>
        },
        {
            name:'',
            cell: (row:User) => <Button onClick={()=>setSelectedUser(row)} variant='contained' color='primary'>Edit</Button>,
            button:true
        }
    ];
    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const response = await DataService.getUsersForOrganization();
            setUsers(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }
    console.log(selectedUser);
    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        loadUsers();
    }, []);
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <DataTable

                    title={'Users'}
                    columns={columns}
                    data={users ? users : []}
                    progressPending={isLoading}
                    progressComponent={<Grid item xs={12}>
                        <TypographySkeleton lineCount={20} withHeadline={true}/>
                    </Grid>}
                />
            </Grid>
        </Grid>
    );
};

export default UserList;