import React, {useState} from 'react'
import {
    Button,
    Grid,
    makeStyles,
    Paper, Snackbar,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import DataService from "../../../services/DataService";
import LoadingIndicator from "../../../components/loading/LoadingIndicator";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        textAlign: 'center',
        margin: theme.spacing(2)
    },
    title: {
        margin: theme.spacing(2)
    }
}))

const exampleCsvFormat = [
    {
        email: 'besnik+4@uppercampus.com',
        firstname: 'student',
        lastname: '1',
        birthday: '02-11-1994'
    },
    {
        email: 'besnik+5@uppercampus.com',
        firstname: 'student',
        lastname: 'two',
        birthday: '03-12-1994'
    },
    {
        email: 'besnik+6@uppercampus.com',
        firstname: 'student',
        lastname: 'Three',
        birthday: '10-30-1994'
    }
];


const PreRegistration = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<SnackBarMessage | undefined>();
    const uploadCsvFile = async (file: any) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('csv', file);
        try {
            await DataService.preregisterStudents(formData);
            setMessage({status: "success", message: 'Successfully preregistered Students!'});
        } catch (e) {
            console.log(e);
            setMessage({status: "error", message: 'There was an error trying to preregister the students!'});
        }
        setIsLoading(false);
    }
    const handleMessageClose = () => {
        setMessage(undefined);
    }
    const resetPreregistration = async () => {
        setIsLoading(true);
        try{
            await DataService.removePreregistrationForStudents();
            setMessage({status: "success", message: 'Successfully removed all preregistrations!'});
        }catch (e){
            console.log(e);
            setMessage({status:'error',message:'There was an error trying to reset all preregistrations!'})
        }
        setIsLoading(false);
    }
    return (
        <Grid container>
            <Grid item xs={12} className={classes.title}>
                <Typography variant={'h6'}>
                    Preregister your students
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Typography align={'justify'}>
                    To make the process of using the Uppercampus App for your students even easier, you can also
                    Preregister them.
                    By preregistering your students the app will automatically fill-out certain informations for the
                    student and
                    automatically enroll them at your school. The student gets recognized through the email that has
                    been used
                    for the preregistering process. The preregistration works in a really simple way: You just need to
                    upload a
                    CSV file that contains the information of your students in a predefined format and Uppercampus will
                    handle
                    the rest. Informations that you don't want have or don't want to enter via an automated process can
                    just be
                    left blank.
                    You can see an example Structure here:
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>email,</TableCell>
                                <TableCell>firstname,</TableCell>
                                <TableCell>lastname,</TableCell>
                                <TableCell>birthday</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exampleCsvFormat.map(entry => {
                                return <TableRow key={entry.firstname + entry.lastname}>
                                    <TableCell>{entry.email},</TableCell>
                                    <TableCell>{entry.firstname},</TableCell>
                                    <TableCell>{entry.lastname},</TableCell>
                                    <TableCell>{entry.birthday}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {isLoading ? <LoadingIndicator/> :
                <Grid item xs={6} className={classes.section}>
                    <input
                        accept="text/csv"
                        hidden
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                            if (e && e.target && e.target.files && e.target.files.length > 0) {
                                uploadCsvFile(e.target.files[0]);
                            }
                        }}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant={"contained"} component="span" color='primary'>
                            Upload Student CSV-File
                        </Button>
                    </label>
                </Grid>
            }
            {!isLoading &&
            <Grid item xs={5} className={classes.section}>
                <Button variant={"contained"} onClick={resetPreregistration}>
                    Reset Preregistrations
                </Button>
            </Grid>
            }
            {message &&
            <Snackbar anchorOrigin={{vertical:'bottom',horizontal:'right'}} open={!!message} autoHideDuration={2000} onClose={handleMessageClose}>
                <Alert severity={message.status}>
                    {message.message}
                </Alert>
            </Snackbar>}
        </Grid>
    );
}

export default PreRegistration;