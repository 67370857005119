import React from 'react';
import {Grid} from '@material-ui/core';
import UserList from "./components/UserList";

const Usermanagement = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <UserList/>
            </Grid>
        </Grid>
    );
};

export default Usermanagement;