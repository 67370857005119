import React from 'react';
import {Divider, List, ListItem, ListItemText, Typography} from "@material-ui/core";

export interface VerticalListNavigationProps {
    navigationItems: {
        display: string,
        onClick: () => void
    }[],
    title?: string
}

const VerticalListNavigation = (props: VerticalListNavigationProps) => {
    const {title, navigationItems} = props;
    const items: JSX.Element[] = [];
    for (let i = 0; i < navigationItems.length; i++) {
        const currentItem = navigationItems[i];
        items.push(<ListItem key={currentItem.display} button onClick={currentItem.onClick}>
            <ListItemText>{currentItem.display}</ListItemText>
        </ListItem>);
        if(navigationItems.length - i > 1){
            items.push(<Divider key={currentItem.display+i}/>);
        }
    }
    return (
        <List component={'nav'}>
            {title && <Typography style={{textAlign:'center'}} variant={'h6'}>{title}</Typography>}
            {items}
        </List>
    );
};

export default VerticalListNavigation;