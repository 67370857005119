import React, {useEffect,useState} from 'react';
import PercentageProgress from "../../../components/progress/PercentageProgress";
import {Grid} from "@material-ui/core";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";
import DataTable from "react-data-table-component";
import DataService from "../../../services/DataService";

const columns = [
    {
        name: '% Completed',
        selector: 'progress',
        sortable: true,
        cell: (row: any) => <PercentageProgress value={row.progress}/>
    },
    {
        name: 'CTE Requirement',
        selector: 'title',
        sortable: true,
        grow: 7
    }
];

const CteReporting = () => {
    const [cteReportData, setCteReportData] = useState<{ title: string, progress: number }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadReportData = async () => {
        setIsLoading(true);
        const response = await DataService.getCteReporting();
        const reportData: { progress: number, title: string }[] = [];
        response.data.forEach(data => {
            let progress:number;
            if(data.user_count > 0){
                progress = (Math.round((data.cte_completed / data.user_count) * 100))
            }else{
                progress = 0;
            }
            reportData.push({title: data.title, progress: progress});
        });
        setCteReportData(reportData);
        setIsLoading(false);
    }
    useEffect(() => {
        loadReportData()
    }, []);
    return(
        <DataTable
            title={'CTE Reporting'}
            columns={columns}
            data={cteReportData}
            progressPending={isLoading}
            progressComponent={<Grid item xs={12}>
                <TypographySkeleton lineCount={10} withHeadline={true}/>
            </Grid>}
        />
    );
};

export default CteReporting;