import React from 'react';
import {Route} from 'react-router-dom';
import Navigation from "../navigation/Navigation";

export class RouteWithNavigation extends Route {
    render() {
        const {component: Component, ...rest}: any = this.props;
        return <Route {...rest}
                      render={props => <>
                          <Navigation/>
                          <Component {...rest} {...props}/>
                      </>}/>
    }
}

export default RouteWithNavigation;