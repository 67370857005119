import React, {useEffect, useState} from 'react';
import DataService from "../../../services/DataService";
import ReportingCard from "./ReportingCard";
import {Link, Typography} from "@material-ui/core";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";
import ExpandableText from "../../../components/text/ExpandableText";

function renderHelpfulLinks(links?: UCLinks[]) {
    const contents: JSX.Element[] = [];
    if (links && links.length > 0) {
        links.forEach(link => {
            contents.push(
                <ExpandableText wrapperProps={{href:link.url,target:'_blank'}} wrapper={Link} key={link.id + 'link'} text={link.description}/>
            );
        });
    } else {
        contents.push(<Typography key={'noLink'}>No Links available!</Typography>);
    }
    return <ReportingCard title={'Helpful Links'} content={contents} help={''}/>
}

const HelpfulLinks = () => {
    const [helpfulLinks, setHelpfulLinks] = useState<UCLinks[]>();
    const [isLoading, setIsLoading] = useState(false);
    const loadHelpfulLinks = async () => {
        setIsLoading(true);
        try {
            const response = await DataService.getHelpfulLinks();
            setHelpfulLinks(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        loadHelpfulLinks();
    }, []);
    return (isLoading ? <TypographySkeleton lineCount={2} withHeadline={true}/> : renderHelpfulLinks(helpfulLinks));
};

export default HelpfulLinks;