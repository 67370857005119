import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import Navigation from "../../components/navigation/Navigation";

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center'
    }
}));

const NoOrganization = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Navigation/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h6'}>Your Account seems to not belong to an organization!</Typography>
                <Typography>You will be able to use the Site after an Admin has assigned you to the organization that
                    you belong to.</Typography>
            </Grid>
        </Grid>
    );
}

export default NoOrganization;