import React from 'react';
import {Grow, makeStyles, Paper, Theme, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textAlign: 'center',
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    titleText: {
        margin: theme.spacing(1),
    }
}));

export interface ReportingCardProps {
    title: string,
    content: JSX.Element | JSX.Element[],
    help: string
}

const ReportingCard = (props: ReportingCardProps) => {
    const classes = useStyles();
    const {title, content} = props;
    return (
        <Grow in timeout={1000}>
            <Paper elevation={1} className={classes.root}>
                <Typography className={classes.titleText} variant={'h5'}>{title}</Typography>
                {content}
            </Paper>
        </Grow>
    );
}

export default ReportingCard;