import React from 'react';
import {Grid} from '@material-ui/core';
import CareerReport from "./components/CareerReport";
import BrandReport from "./components/BrandReport";
import StudentReport from "./components/StudentReport";
import CalendarReport from "./components/calendarReport/CalendarReport";
import HelpfulLinks from "./components/HelpfulLinks";
import SpotlightLinks from "./components/SpotlightLinks";

const Dashboard = () => {
    return (
        <Grid container>
            <Grid item xs={4}>
                <CalendarReport/>
            </Grid>
            <Grid item xs={4}>
                <CareerReport/>
                <BrandReport/>
            </Grid>
            <Grid item xs={4}>
                <StudentReport/>
                <SpotlightLinks/>
                <HelpfulLinks/>
            </Grid>
        </Grid>
    );
};

export default Dashboard;