import React from 'react';
import OnboardingInstructions from "./components/OnboardingInstructions";
import {Grid, makeStyles, Theme} from "@material-ui/core";
import PreRegistration from "./components/PreRegistration";

const useStyles = makeStyles((theme: Theme) => ({
    root:{
        padding:theme.spacing(2)
    }
}));

const OnboardStudents = () => {
    const classes = useStyles();
    return(
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <PreRegistration/>
            </Grid>
            <Grid item xs={12}>
                <OnboardingInstructions/>
            </Grid>
        </Grid>
    );
}

export default OnboardStudents;