import React from 'react';
import {AppBar, makeStyles, Theme, Toolbar, Typography} from '@material-ui/core';
import {useStores} from "../../hooks/useStores";
import {observer} from "mobx-react";
import NavigationItem from "./NavigationItem";
import ProfileMenu from "./ProfileMenu";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        marginRight: theme.spacing(1),
        flex: 1
    },
    navigationMenu: {
        display: 'flex',
        flexGrow: 3,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

const Navigation = observer(() => {
    const {contextStore} = useStores();
    const classes = useStyles();
    return (
        <AppBar position={'static'}>
            <Toolbar>
                <Typography variant={'h6'} className={classes.logo}>
                    {contextStore.organization && contextStore.organization.name ? contextStore.organization.name : ''}
                </Typography>
                <div className={classes.navigationMenu}>
                    {contextStore.navigationItems.map(item => <NavigationItem key={item.display} display={item.display}
                                                                              linkTo={item.linkTo}/>)}
                    <ProfileMenu/>
                </div>
            </Toolbar>
        </AppBar>
    );
})

export default Navigation;