import React, {useEffect, useState} from 'react';
import DataService from "../../../services/DataService";
import {Typography} from "@material-ui/core";
import ReportingCard from "./ReportingCard";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";

function renderStudentReporting(registered: number, thirtyDayActive: number) {
    const components = [];
    components.push(<Typography variant={'subtitle1'} key={'StudentRegistered'}>Registered: {registered}</Typography>);
    components.push(<Typography variant={'subtitle1'} key={'ThirtyDayStudents'}>30-Day Active: {thirtyDayActive}</Typography>);
    return <ReportingCard title={'Students'} content={components} help={''}/>
}

const StudentReport = () => {
    const [registered, setRegistered] = useState<number>(0);
    const [thirtyDayActive, setThirtyDayActive] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const loadReportingData = async () => {
        setIsLoading(true);
        try {
            const registeredResponse = await DataService.getUniqueUsersReporting();
            setRegistered(registeredResponse.data);
            const thirtyDaysResponse = await DataService.getUniqueUsersThirtyDays();
            setThirtyDayActive(thirtyDaysResponse.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        loadReportingData();
    }, []);
    return (
        <>
            {isLoading ? <TypographySkeleton lineCount={2}
                                             withHeadline={true}/> : renderStudentReporting(registered, thirtyDayActive)}
        </>
    );
}

export default StudentReport;