import React from 'react';
import moment from "moment";
import {Container, Typography} from "@material-ui/core";
import CalendarReportEvent from "./CalendarReportEvent";
import ReportingCard from "../ReportingCard";
import {Chrono} from 'react-chrono';

declare interface CalendarReportEventListProps {
    report?: CustomCalendarReport
}

const CalendarReportEventList = (props: CalendarReportEventListProps) => {
    const {report} = props;
    if (report) {
        const contents: JSX.Element[] = [];
        const items: { title: string, cardTitle: string }[] = [];
        Object.keys(report).sort((a, b) => moment(a).isBefore(moment(b)) ? -1 : 1).forEach((key) => {
            const components: JSX.Element[] = [];
            const isBefore = moment(key).isBefore(moment());
            items.push({
                title: moment(key).format("dddd MMM DD"),
                cardTitle: `${report[key].length} Events scheduled.`
            });
            report[key].forEach(event => {
                components.push(<CalendarReportEvent key={key + event.title} event={event} isPast={isBefore}/>);
            });
            contents.push(<Container key={key}>{components}</Container>)
        });
        return (
            <Chrono
                hideControls
                theme={{
                    primary: "#072E4E",
                    secondary: "white",
                    cardBgColor: "white",
                    cardForeColor: "black"
                }}
                mode={'VERTICAL'}
                items={items}>
                {contents}
            </Chrono>
        );
    } else return <ReportingCard title={'Calender'} content={<Typography>No Events Present</Typography>} help={''}/>
}

export default CalendarReportEventList;