import React from 'react';
import {makeStyles, Theme, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";

export interface NavigationItemProps {
    display: string,
    linkTo: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: 2,
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    }
}));

const NavigationItem = (props: NavigationItemProps) => {
    const {display, linkTo} = props;
    const classes = useStyles();
    const history = useHistory();
    return (
        <Typography onClick={() => history.push(linkTo)} className={classes.root}>
            {display}
        </Typography>
    );
}

export default NavigationItem;