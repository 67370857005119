import React from 'react';
import {Grid, makeStyles, Theme} from "@material-ui/core";
import VerticalListNavigation from "../../components/navigation/VerticalListNavigation";
import CteReporting from "./components/CteReporting";
import StudentReport from "./components/StudentReport";
import {useParams} from 'react-router';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(0.5),
        paddingRight:theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
}));

function renderActiveReport(reportName:string){
    switch(reportName){
        case 'cte':
            return <CteReporting/>
        case 'student':
            return <StudentReport/>
        default:
            return <CteReporting/>
    }
}

const Reportings = () => {
    const {reportId} = useParams() as {reportId:string};
    const history = useHistory();
    const classes = useStyles();
    const navigation = [
        {
            display:'CTE Reporting',
            onClick: () => history.push('cte')
        },
        {
            display:'Student Reporting',
            onClick: () => history.push('student')
        }
    ];
    return (
        <Grid container className={classes.root} spacing={3}>
            <Grid xs={2} item>
                <VerticalListNavigation navigationItems={navigation} title={'Reportings'}/>
            </Grid>
            <Grid xs={10} item>
                {renderActiveReport(reportId)}
            </Grid>
        </Grid>
    );
}

export default Reportings;