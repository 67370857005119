import {makeAutoObservable, observable} from "mobx";
import DataService from "../services/DataService";
import LogoutButton from "../components/logout/LogoutButton";
import React from "react";

const defaultProfileMenu = [<LogoutButton/>];
const defaultNavigationItems = [
    {display: 'Dashboard', linkTo: '/'},
    {display: 'Reports', linkTo: '/reports/cte'},
    {display: 'Onboard Students', linkTo: '/students/onboard'},
    {display: 'Usermanagement', linkTo: '/user_management'}
]

export type Feature = {
    name: string,
    enabled: Boolean
}

export class ContextStore {
    user: User | null = null;
    features: Feature[] | null = null;
    organization: Organization | null = null;
    profileMenu: JSX.Element[] = defaultProfileMenu;
    navigationItems: { display: string, linkTo: string }[] = defaultNavigationItems;
    newUser: boolean = false;

    constructor() {
        // Since JSX-Elements themselve cannot be made observables we are overriding the default that makeAutoObservable does with a ref.
        makeAutoObservable(this, {profileMenu: observable.ref});
    }

    setUser(user: User) {
        this.user = observable(user);
    };

    setFeatures(features: Feature[]) {
        this.features = observable(features);
    };

    pushFeature(feature: Feature) {
        this.features?.push(feature);
    }

    setOrganization(organization: Organization) {
        this.organization = observable(organization);
        DataService.setOrganizationId(organization.id);
    };

    async loadContext() {
        const response = await DataService.getCurrentUser();
        this.setUser(response.data);
        if (response.data?.requestedOrganizationContext?.organization) {
            this.setOrganization(response.data.requestedOrganizationContext.organization);
        } else {
            this.newUser = true;
            this.navigationItems.splice(0, this.navigationItems.length);
        }
    }
}