import React, {useEffect, useState} from 'react';
import DataService from "../../../services/DataService";
import {Avatar, Grid, TextField, Typography, makeStyles, Theme, InputAdornment} from "@material-ui/core";
import {Search} from '@material-ui/icons';
import TypographySkeleton from "../../../components/loading/TypographySkeleton";
import DataTable from "react-data-table-component";
import _ from 'lodash';
import LoadingIndicator from "../../../components/loading/LoadingIndicator";

const columns = [
    {
        name: '',
        selector: 'picture',
        cell: (row: StudentReport) => <Avatar alt={'Profile Picture'} src={row.picture}/>
    },
    {
        name: 'Student Name',
        selector: 'firstname',
        cell: (row: StudentReport) => <Typography>{row.firstname} {row.lastname}</Typography>
    },
    {
        name: 'Email',
        selector: 'email'
    },
    {
        name: 'Last Login',
        selector: 'last_login'
    },
    {
        name: '30-Day Logins',
        selector: 'thirty_day_login'
    },
    {
        name: 'CTE Status',
        selector: 'cte_completed',
        cell: (row: StudentReport) => <Typography>{row.cte_completed}/{row.cte_total}</Typography>
    }
];

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        spacing: theme.spacing(1),
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
}));

const StudentReport = () => {
    const [studentReports, setStudentReports] = useState<ListResponse<StudentReport>>();
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const loadStudentReports = async (page: number = 1, filter?: string) => {
        setIsLoading(true);
        try {
            const response = await DataService.getStudentReporting(page, filter);
            setStudentReports(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
        setFirstLoading(false);
    }
    const applyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        loadStudentReports(1, event.target.value);
    }
    useEffect(() => {
        loadStudentReports();
    }, []);
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <DataTable
                    title={'Student Reporting'}
                    subHeader
                    subHeaderComponent={<TextField
                        placeholder={'search'}
                        InputProps={{
                            startAdornment: (<InputAdornment position={"start"}><Search/></InputAdornment>),
                        }}
                        onChange={_.debounce<any>(applyFilter, 500)}
                    />}
                    columns={columns}
                    data={studentReports && studentReports.results ? studentReports.results:[]}
                    progressPending={isLoading}
                    pagination={studentReports && studentReports.count > 20}
                    paginationServer
                    paginationTotalRows={studentReports?.count}
                    paginationRowsPerPageOptions={[20]}
                    onChangePage={loadStudentReports as (page: number) => void}
                    progressComponent={<Grid item xs={12}>
                        {firstLoading ? <TypographySkeleton lineCount={20} withHeadline={true}/> : <LoadingIndicator/>}
                    </Grid>}
                />
            </Grid>
        </Grid>
    );
};

export default StudentReport;