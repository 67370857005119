import React, {useEffect, useState} from 'react';
import DataService from "../../../services/DataService";
import {Typography} from "@material-ui/core";
import ReportingCard from "./ReportingCard";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";
import ExpandableText from "../../../components/text/ExpandableText";
import ReactPlayer from "react-player/lazy";

function renderSpotlightLinks(links?: UCLinks[]) {
    const contents = [];
    if (links && links.length > 0) {
        links.forEach(link => {
            if(!link.description || link.description === ''){
                link.description = 'No Description available.'
            }
            contents.push(<ReactPlayer width='100%' url={link.url} key={link.url+link.id}/>);
            contents.push(<ExpandableText text={link.description} key={link.id}/>);
            contents.push(<br key={link.id+'br'}/>);
        });
    } else {
        contents.push(<Typography key={'NoSpotlightLinks'}>No Links available!</Typography>)
    }
    return <ReportingCard title={'Spotlight'} content={contents} help={''}/>
}


const SpotlightLinks = () => {
    const [spotlightLinks, setSpotlightLinks] = useState<UCLinks[]>();
    const [isLoading, setIsLoading] = useState(false);
    const loadSpotlightLinks = async () => {
        setIsLoading(true);
        try {
            const response = await DataService.getSpotlightLinks();
            setSpotlightLinks(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        loadSpotlightLinks();
    }, []);
    return (
        <>
            {isLoading ? <TypographySkeleton lineCount={2} withHeadline={true}/> : renderSpotlightLinks(spotlightLinks)}
        </>
    );
};

export default SpotlightLinks;