import React from 'react';
import {makeStyles, Paper, Theme, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'

interface TypographySkeletonProps {
    lineCount: number,
    withHeadline: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(1)
    },
    typos: {
        margin: theme.spacing(1)
    }
}));

const TypographySkeleton = (props: TypographySkeletonProps) => {
    const {lineCount, withHeadline} = props;
    const children = [];
    const classes = useStyles();
    for (let i = 0; i < lineCount; i++) {
        children.push(<Typography className={classes.typos} key={i + 'skeleton'}><Skeleton/></Typography>);
    }
    return (
        <Paper className={classes.root} elevation={3} style={{margin: 10}}>
            {withHeadline && <Typography className={classes.typos} variant={'h3'}><Skeleton/></Typography>}
            {children}
        </Paper>
    );
}

export default TypographySkeleton;