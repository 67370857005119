import React, {useState} from 'react';
import {makeStyles, Theme, Typography} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";

export interface ExpandableTextProps {
    text: string,
    className?: string,
    variant?: Variant,
    wrapper?: React.ComponentType<any>
    wrapperProps?:any
}

const useStyles = makeStyles((theme: Theme) => ({
    triggerText: {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
        fontWeight: 'bold'
    }
}));

const ExpandableText = (props: ExpandableTextProps) => {
    const {text, className, variant, wrapper: Component,wrapperProps} = props;
    const expansionNeeded = text.length > 80;
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(!expansionNeeded);
    return (
        <>
            {Component ?
                <Component {...wrapperProps}>
                    <Typography variant={variant ? variant : undefined} className={className}
                                noWrap={!isExpanded}>{text}</Typography>
                </Component> :
                <Typography variant={variant ? variant : undefined} className={className}
                            noWrap={!isExpanded}>{text}</Typography>
            }
            {expansionNeeded && <Typography className={classes.triggerText}
                                            onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Show less' : 'Show More'}</Typography>}
        </>
    );
}

export default ExpandableText;