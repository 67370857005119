import {unstable_createMuiStrictModeTheme as createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette:{
        primary:{
            main:'#072E4E',
        }
    }
});

export default theme;