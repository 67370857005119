import {ContextStore} from "../stores/ContextStore";
import React from 'react';

const stores = {
    contextStore: new ContextStore()
}

const storeContext = React.createContext(stores);

export const StoreProvider = (props: any) => {
    return <storeContext.Provider value={stores}>{props.children}</storeContext.Provider>
}

export const useStores = () => {
    const stores = React.useContext(storeContext)
    if (!stores) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.')
    }
    return stores
};