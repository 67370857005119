import axios, {AxiosResponse} from 'axios';

const BASE_URL = window.location.origin.includes('localhost') ? 'http://localhost:8081/' : window.location.origin.includes('stg') ? 'https://admin-api-stg.uppercampus.cloud/' : 'https://admin-api-prd.uppercampus.cloud/';

export default class DataService {
    static accessKey: string;
    static organizationId: string;
    static authenticatedAxios = axios.create({
        baseURL: BASE_URL
    });

    static setAccessKey(key: string) {
        this.accessKey = key;
        this.authenticatedAxios.defaults.headers.Authorization = `Bearer ${key}`;
    }

    static setOrganizationId(organizationId: string) {
        this.organizationId = organizationId;
        this.authenticatedAxios.defaults.headers.organization_id = organizationId;
    }

    static async getClickedCareerReporting(): Promise<AxiosResponse<SimpleKeyValuesReporting[]>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports`, {
            params: {
                type: 'career_clicked'
            }
        });
    }

    static async getClickedBrandReporting(): Promise<AxiosResponse<SimpleKeyValuesReporting[]>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports`, {
            params: {
                type: 'brand_clicked'
            }
        });
    }

    static async getUniqueUsersReporting(): Promise<AxiosResponse<number>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports`, {
            params: {
                type: 'unique_users'
            }
        });
    }

    static async getUniqueUsersThirtyDays(): Promise<AxiosResponse<number>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports`, {
            params: {
                type: 'unique_users_thirty'
            }
        });
    }

    static async getCustomEvents(request: CustomCalendarRequest): Promise<AxiosResponse<CustomCalendarReport>> {
        return await this.authenticatedAxios.get(`/organizations/${this.organizationId}/events/calendar`, {
            params: {
                start_date: request.start_date,
                end_date: request.end_date
            }
        });
    }

    static async getHelpfulLinks(): Promise<AxiosResponse<UCLinks[]>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/links`, {
            params: {
                type: 'helpful_links'
            }
        });
    }

    static async getSpotlightLinks(): Promise<AxiosResponse<UCLinks[]>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/links`, {
            params: {
                type: 'spotlight'
            }
        });
    }

    static async getCurrentUser(): Promise<AxiosResponse<User>> {
        return await this.authenticatedAxios.get('/users/currentUser');
    }

    static async getCteReporting(): Promise<AxiosResponse<CteReport[]>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports`, {
            params: {
                type: 'cte_completions'
            }
        });
    }

    static async getStudentReporting(page: number = 1, filter?: string): Promise<AxiosResponse<ListResponse<StudentReport>>> {
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/reports/students`, {
            params: {
                page: page,
                filter: filter
            }
        });
    }

    static async getHighschool(schoolId: number) {
        return await this.authenticatedAxios.get(`/highschools/${schoolId}`);
    }

    static async preregisterStudents(formData: any) {
        return await this.authenticatedAxios.post(`organizations/${this.organizationId}/preregister/csv`, formData, {
            headers: {
                'content-type': 'multipart/formdata'
            }
        });
    }

    static async removePreregistrationForStudents(){
        return await this.authenticatedAxios.delete(`organizations/${this.organizationId}/preregister/csv`);
    }

    static async getUsersForOrganization(): Promise<AxiosResponse<User[]>>{
        return await this.authenticatedAxios.get(`organizations/${this.organizationId}/users`);
    }
}