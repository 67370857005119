import React, {useEffect, useState} from 'react';
import DataService from "../../../../services/DataService";
import moment from 'moment';
import {useStores} from "../../../../hooks/useStores";
import {observer} from "mobx-react";
import TypographySkeleton from "../../../../components/loading/TypographySkeleton";
import CalendarReportEventList from "./CalendarReportEventList";

const CalendarReport = observer(() => {
    const [calendarReport, setCalendarReport] = useState<CustomCalendarReport>();
    const [isLoading, setIsLoading] = useState(false);
    const {contextStore} = useStores();
    useEffect(() => {
        const loadCalendarReport = async () => {
            setIsLoading(true);
            try {
                const request: CustomCalendarRequest = {
                    end_date: moment().add(30, 'days').format("YYYY-MM-DD hh-mm-ss"),
                    start_date: moment().format("YYYY-MM-DD hh-mm-ss")
                }
                const response = await DataService.getCustomEvents(request);
                setCalendarReport(response.data);
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        };
        loadCalendarReport();
    }, [contextStore.organization?.entity_id]);
    return (
        <>
            {isLoading ? <TypographySkeleton lineCount={20} withHeadline={true}/> :
                <CalendarReportEventList report={calendarReport}/>}
        </>
    );
})

export default CalendarReport;