import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import {useStores} from "../../../hooks/useStores";
import DataService from "../../../services/DataService";
import QRCode from "qrcode.react";
import LoadingIndicator from "../../../components/loading/LoadingIndicator";

const useStyles = makeStyles((theme: Theme) => ({
    section:{
        margin:theme.spacing(2)
    },
    paper:{
        padding:theme.spacing(1),
        textAlign:'center'
    }
}));

const OnboardingInstructions = () => {
    const {contextStore} = useStores();
    const [activationCode,setActivationCode] = useState<string>();
    const loadActivationCode = async () => {
        if(contextStore.organization?.entity_id){
            const response = await DataService.getHighschool(contextStore.organization?.entity_id);
            setActivationCode(response.data.activation_code);
        }
    }
    useEffect(() => {
        loadActivationCode()
        // eslint-disable-next-line
    }, []);
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} className={classes.section}>
                <Typography variant={'h6'}>
                    There are 2 easy steps for students to get started with UpperCampus.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align={'justify'} className={classes.section}>
                    1.Direct your students to download the UpperCampus mobile app. They can find the app by searching
                    for "UpperCampus" in the app stores, or by using the links here:
                </Typography>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={3} className={classes.section}>
                <Paper className={classes.paper}>
                    <Typography>To download from the Apple App Store go to</Typography>
                    <a href={'http://appstore.uppercampus.com'} rel="noreferrer noopener" target={'_blank'}>http://appstore.uppercampus.com</a>
                </Paper>
            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={3} className={classes.section}>
                <Paper className={classes.paper}>
                    <Typography>To download from the Google Play Store, go to</Typography>
                    <a href={'http://playstore.uppercampus.com'} rel="noreferrer noopener" target={'_blank'}>http://playstore.uppercampus.com</a>
                </Paper>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} className={classes.section}>
                <Typography align={'justify'}>
                    2.Share your custom UpperCampus QR & Activation code so that they can assign themselves to your
                    specific school. By doing this, you will be able to create content that only your students will have
                    access to. You will also get access to reporting based on student engagement and activity.
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Typography align={'justify'}>
                    Copy and paste this QR code, or numerical activation code, to your internal marketing materials so
                    that
                    students are aware of it. During the registration process, students will be asked to enter that code
                    (or
                    scan the QR code). If they don't do this during registration, they will always have the ability to
                    do it
                    at a later time through their User Settings in the mobile app.
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paper}>
                {activationCode ? <QRCode value={activationCode}/> : <LoadingIndicator/>}
            </Grid>
            <Grid item xs={12} className={classes.paper}>
                {activationCode && `Activation Code : ${activationCode}` }
            </Grid>
        </Grid>
    );
}

export default OnboardingInstructions;