import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './Router';
import {MuiThemeProvider} from "@material-ui/core";
import theme from './themes/defaultTheme';
import {Auth0Provider} from "@auth0/auth0-react";

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={'uppercampus.auth0.com'}
            clientId={'u2agsLBImQLP6DEwHdAK3LjoukS2Lwsv'}
            redirectUri={window.location.origin}
            cacheLocation={'localstorage'}
            useRefreshTokens={true}
            audience={'PdcSbHXZnDIwRYaSyxGDa1AlEndxLmTd'}
        >
            <MuiThemeProvider theme={theme}>
                <Router/>
            </MuiThemeProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
