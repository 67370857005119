import React, {useEffect, useState} from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import Dashboard from "./views/dashboard/Dashboard";
import {useAuth0} from "@auth0/auth0-react";
import DataService from "./services/DataService";
import {useStores} from "./hooks/useStores";
import RouteWithNavigation from "./components/route/RouteWithNavigation";
import LoadingIndicator from "./components/loading/LoadingIndicator";
import {observer} from "mobx-react";
import NoOrganization from "./views/noOrganization/NoOrganization";
import Reportings from "./views/reportings/Reportings";
import OnboardStudents from "./views/onboardStudents/OnboardStudents";
import Usermanagement from "./views/usermanagement/Usermanagement";

const Router = observer(() => {
        const {isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect} = useAuth0();
        const [authenticationLoading, setAuthenticationLoading] = useState(true);
        const {contextStore} = useStores();
        useEffect(() => {
            if (!isLoading && !isAuthenticated) {
                // noinspection JSIgnoredPromiseFromCall
                loginWithRedirect();
            } else if (!isLoading && isAuthenticated) {
                getAccessTokenSilently().then((accessToken: string) => {
                    DataService.setAccessKey(accessToken);
                    contextStore.loadContext().finally(() => {
                        setAuthenticationLoading(false);
                    });
                }).catch((e) => {
                    console.error(e);
                    // noinspection JSIgnoredPromiseFromCall
                    loginWithRedirect();
                });
            }
            /* Since we are depending on Auth0 and it's functions inside of the useEffect to realize the authorization we have to ignore the
            missing DependencyWarning here. I searched a lot to fix this issue but i couldn't get to a good state for that.
             */
        }, [isLoading, isAuthenticated]);// eslint-disable-line react-hooks/exhaustive-deps
        return (
            <>
                {authenticationLoading ? <LoadingIndicator/> : contextStore.newUser ? <NoOrganization/> :
                    <BrowserRouter>
                        <Switch>
                            <RouteWithNavigation exact path={'/'} component={() => <Dashboard/>}/>
                            <RouteWithNavigation exact path={'/reports/:reportId'} component={() => <Reportings/>}/>
                            <RouteWithNavigation exact path={'/students/onboard'} component={() => <OnboardStudents/>}/>
                            <RouteWithNavigation exact path={'/user_management'} component={() => <Usermanagement/>}/>
                            <RouteWithNavigation path={'*'} component={() => <div>Not Found!</div>}/>
                        </Switch>
                    </BrowserRouter>
                }
            </>
        );
    }
)
export default Router;