import React from 'react';
import {CircularProgress, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));


const LoadingIndicator = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <CircularProgress/>
        </div>
    );
}

export default LoadingIndicator;