import React, {useEffect, useState} from 'react'
import DataService from "../../../services/DataService";
import {makeStyles, Typography} from "@material-ui/core";
import ReportingCard from "./ReportingCard";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";

const useStyles = makeStyles(() => ({
    careerText: {
        textAlign: 'left',
        margin: 10
    }
}));

const CareerReport = () => {
    const [careersClicked, setCareersClicked] = useState<SimpleKeyValuesReporting[]>();
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const renderCareerClickedReporting = () => {
        let components: JSX.Element | JSX.Element[];
        if (careersClicked && careersClicked.length > 0) {
            components = careersClicked.map((career, index) => {
                return <Typography className={classes.careerText}
                                   key={career.key}>{index + 1}. {career.key}</Typography>
            });
        } else {
            components = <Typography key={'NoCareer'}>No Reporting Data available!</Typography>
        }
        return <ReportingCard title={'Top 10 Careers'} content={components} help={''}/>
    }
    const loadCareersClickedReport = async () => {
        setIsLoading(true);
        try {
            const response = await DataService.getClickedCareerReporting();
            setCareersClicked(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loadCareersClickedReport();
    }, []);
    return (isLoading ? <TypographySkeleton lineCount={10} withHeadline={true}/> : renderCareerClickedReporting());
}

export default CareerReport;