import React from 'react'
import {Box, CircularProgress, Typography} from "@material-ui/core";

const PercentageProgress = (props:{value:number}) => {
    const {value} = props;
    return(
        <Box position='relative' display='inline-flex'>
            <CircularProgress variant={'determinate'} value={value}/>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{value}%</Typography>
            </Box>
        </Box>
    );
}

export default PercentageProgress;