import React, {useEffect, useState} from 'react'
import DataService from "../../../services/DataService";
import {makeStyles, Theme, Typography} from "@material-ui/core";
import ReportingCard from "./ReportingCard";
import TypographySkeleton from "../../../components/loading/TypographySkeleton";

const useStyles = makeStyles((theme: Theme) => ({
    brandText:{
        textAlign:'left',
        margin:theme.spacing(1)
    }
}));

const CareerReport = () => {
    const [brandsClicked, setBrandsClicked] = useState<SimpleKeyValuesReporting[]>();
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const loadBrandsClickedReport = async () => {
        setIsLoading(true);
        try {
            const response = await DataService.getClickedBrandReporting();
            setBrandsClicked(response.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };
    const renderBrandsClickedReporting = () => {
        let components: JSX.Element | JSX.Element[];
        if (brandsClicked && brandsClicked.length > 0) {
            components = brandsClicked.map((brand, index) => {
                return <Typography className={classes.brandText} key={brand.key}>{index+1}. {brand.key}</Typography>
            });
        } else {
            components = <Typography key={'NoBrands'}>No Reporting Data available!</Typography>
        }
        return <ReportingCard title={'Top 10 Brands'} content={components} help={''}/>
    }
    useEffect(() => {
        loadBrandsClickedReport();
    }, []);
    return (isLoading ? <TypographySkeleton lineCount={10} withHeadline={true}/> : renderBrandsClickedReporting());
}

export default CareerReport;