import React from "react";
import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {AccessTime} from "@material-ui/icons";
import ExpandableText from "../../../../components/text/ExpandableText";
import moment from "moment";

export interface CalendarReportEventProps {
    event: CustomCalendarReportEvent,
    isPast: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    greyText: {
        color: theme.palette.grey["600"]
    },
    normalText: {
        color: theme.palette.primary.main
    },
    clockIconGrid: {
        textAlign: 'right',
    },
    dateGrid: {
        textAlign: 'left'
    }
}));

const CalendarReportEvent = (props: CalendarReportEventProps) => {
    const {event, isPast} = props;
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
                <ExpandableText variant={'subtitle1'} text={event.title}
                                className={isPast ? classes.greyText : classes.normalText}/>
            </Grid>
            <Grid item xs={1}>
                <AccessTime/>
            </Grid>
            <Grid item xs={11}>
                <Typography variant={'body2'} className={isPast ? classes.greyText : classes.normalText}>
                    {moment(event.started_on).format("L LT")} to {moment(event.ended_on).format("L LT")}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default CalendarReportEvent;