import React, {useState} from 'react';
import {IconButton, makeStyles, Menu, MenuItem} from "@material-ui/core";
import {AccountCircle} from "@material-ui/icons";
import {useStores} from "../../hooks/useStores";

function renderProfileMenuItems(items: JSX.Element[]) {
    return items.map(item => {
        return <MenuItem key={item.key}>{item}</MenuItem>
    });
}

const useStyles = makeStyles(() => ({
    profileIcon: {
        flex: 1
    }
}));

const ProfileMenu = () => {
    const classes = useStyles();
    const {contextStore} = useStores();
    const [anchorEl, setAnchorEl] = useState<null | EventTarget>(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    }
    const handleOnClose = () => {
        setAnchorEl(null);
    }
    return (
        <>
            <IconButton
                className={classes.profileIcon}
                color={'inherit'}
                onClick={(event => handleMenuClick(event))}
            >
                <AccountCircle/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl as Element}
                keepMounted
                onClose={handleOnClose}
            >
                {renderProfileMenuItems(contextStore.profileMenu)}
            </Menu>
        </>
    );
}

export default ProfileMenu;